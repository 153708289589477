import isElectron from "is-electron";

import "./styles/main.css";

import "highlight.js/styles/github.css";

// Add svg files to sprite sheet
import "./assets/images/icons/icon-menu.svg?sprite";
import "./assets/images/icons/icon-youtube.svg?sprite";
import "./assets/images/icons/icon-instagram.svg?sprite";
import "./assets/images/icons/icon-linked-in.svg?sprite";
import "./assets/images/icons/icon-twitter.svg?sprite";
import "./assets/images/icons/icon-download.svg?sprite";
import "./assets/images/icons/icon-windows.svg?sprite";
import "./assets/images/icons/icon-apple.svg?sprite";
import "./assets/images/icons/icon-linux.svg?sprite";
import "./assets/images/visual-programming-1.svg?sprite";
import "./assets/images/visual-programming-2.svg?sprite";
import "./assets/images/visual-programming-3.svg?sprite";
import "./assets/images/logo-ibm.svg?sprite";
import "./assets/images/logo-google.svg?sprite";
import "./assets/images/logo-digital-catapult.svg?sprite";

const APP_NAME = "Nodes";

// Elements
const MainNavElement = document.querySelector(`.MainNav`);
const ButtonDownloadElement = document.querySelector(
  `.MainHeader .Button--download`,
);
const DownloadNavElement = document.querySelector(`.DownloadNav`);
const DownloadNavVersionElement =
  document.querySelector(`.DownloadNav-version`);
const DownloadNavLinkElements = document.querySelectorAll(`.DownloadNav a`);
const ButtonDownloadElements = document.querySelectorAll(`.Button--download`);
const ButtonMenuElement = document.querySelector(`.MainHeader .Button--menu`);
const MainNavLinkElements = document.querySelectorAll(`.MainNav a`);
const VideoElements = document.querySelectorAll("video[autoplay]");
const YearElements = document.querySelectorAll(`.u-year`);
// Safari bug: has to be single quotes and not backticks: https://www.reddit.com/r/firefox/comments/5nbmqi/on_handling_invalid_selector_strings/
const AnchorElements = document.querySelectorAll('a[href|="#"]');
const MarkdownLinksElements = document.querySelectorAll(
  'a[href*=".md"]:not([target=_blank])',
);
const HomeTestimoniesBlockquoteElements = document.querySelectorAll(
  ".HomeTestimoniesContainer blockquote",
);
const HomeTestimoniesContainerElement = document.querySelector(
  ".HomeTestimoniesContainer",
);
const PostLinksElements = document.querySelectorAll(`.Post a`);
const TableOfContentsElement = document.querySelector(".TableOfContents");
let TableOfContentsLinksElements;
let TitleElements;
let titleElementsOffsets = {};
let HomeTestimoniesMaxHeight = 0;

// Methods
const setTitleElementsOffsets = () => {
  if (TitleElements) {
    TitleElements.forEach((element) => {
      titleElementsOffsets[element.getAttribute("id")] = element.offsetTop;
    });
  }
};

const updateTableOfContents = () => {
  const scrollY = window.scrollY;
  let sectionId;

  const keys = Object.keys(titleElementsOffsets);
  for (let i = 0; i < keys.length; i++) {
    const id = keys[i];
    if (scrollY >= titleElementsOffsets[id]) {
      sectionId = id;
    }
  }

  if (sectionId) {
    TableOfContentsLinksElements.forEach((element) => {
      const linkId = element.getAttribute("href").substring(1);

      if (linkId === sectionId) {
        element.parentElement.classList.add("active");
      } else {
        element.parentElement.classList.remove("active");
      }
    });
  }
};

const updateHomeTestimoniesMaxHeight = () => {
  if (HomeTestimoniesBlockquoteElements.length) {
    HomeTestimoniesBlockquoteElements.forEach((element) => {
      HomeTestimoniesMaxHeight = Math.max(
        HomeTestimoniesMaxHeight,
        element.offsetHeight,
      );
    });
    HomeTestimoniesContainerElement.style.height = `${HomeTestimoniesMaxHeight}px`;
  }
};

const init = async () => {
  // Rewrite markdown links
  MarkdownLinksElements.forEach((element) => {
    if (isElectron()) return;
    const elementHref = element.getAttribute("href");
    const newHref = elementHref.replace(/.md/, "/");

    if (elementHref.indexOf("/") !== -1) {
      element.href = `/${newHref}`;
    } else {
      const { origin, pathname } = window.location;
      const url = `${origin}${pathname}`;

      if (url.charAt(url.length - 1) !== "/") {
        element.href = `${origin}${pathname.substring(
          0,
          pathname.lastIndexOf("/"),
        )}/${newHref}`;
      } else {
        element.href = `${origin}${pathname.substring(
          0,
          pathname.lastIndexOf("/", pathname.lastIndexOf("/") - 1),
        )}/${newHref}`;
      }
    }
  });

  // Handle MainNav responsive menu
  if (ButtonMenuElement) {
    ButtonMenuElement.addEventListener("click", () => {
      MainNavElement.classList.toggle("MainNav--active");
    });
  }

  // Handle MainNav responsive menu
  if (ButtonDownloadElements && ButtonDownloadElement) {
    ButtonDownloadElements.forEach((element) => {
      element.addEventListener("click", () => {
        ButtonDownloadElement.classList.toggle("Button--download--active");
        if (MainNavElement) MainNavElement.classList.add("MainNav--active");
      });
    });
    document.addEventListener("click", (event) => {
      if (
        !event.target.classList.contains("Button--download") &&
        !DownloadNavElement.contains(event.target)
      ) {
        ButtonDownloadElement.classList.remove("Button--download--active");
      }
    });
  }

  // Handle download click
  if (DownloadNavElement) {
    let latest = {};
    try {
      latest = await (await fetch("/download/latest.json")).json();
      const { version, url } = latest;

      DownloadNavVersionElement.innerText = version;

      DownloadNavLinkElements.forEach((element) => {
        const extension = element.dataset.extension;
        element.href = `${url}${APP_NAME}-${version}.${extension}`;

        element.addEventListener("click", (event) => {
          if (window.gtag) {
            window.gtag("event", "main_header_download_link", {
              event_label: extension,
            });
          }
        });
      });
    } catch (error) {
      console.error(error);
    }
  }

  // Handle MainNav active state
  MainNavLinkElements.forEach((element) => {
    if (element.getAttribute("href") === window.location.pathname) {
      element.classList.add("active");
    }
  });

  // Handle copyright year
  const year = new Date().getFullYear();
  if (YearElements) {
    YearElements.forEach((element) => {
      element.innerText = year;
    });
  }

  // Handle autoplay
  if (VideoElements.length) {
    VideoElements.forEach((element) => {
      document.body.addEventListener(
        "touchstart",
        function onTouch(e) {
          if (!element.playing) {
            element.play();
          }
          document.body.removeEventListener("touchstart", onTouch, false);
        },
        false,
      );
    });
  }

  // Handle ToC active state
  if (TableOfContentsElement) {
    TableOfContentsLinksElements = TableOfContentsElement.querySelectorAll("a");
    TitleElements = document.querySelectorAll("h2[id]");
  }

  // Update ToC on anchor click
  if (AnchorElements) {
    AnchorElements.forEach((element) => {
      element.addEventListener("click", updateTableOfContents);
    });
  }

  // Testimonies carousel
  if (HomeTestimoniesBlockquoteElements.length) {
    const testimoniesCount = HomeTestimoniesBlockquoteElements.length;
    let currentIndex = 0;

    const showTestimony = () => {
      HomeTestimoniesBlockquoteElements.forEach((element) => {
        element.classList.remove("active");
      });

      HomeTestimoniesBlockquoteElements[
        currentIndex % testimoniesCount
      ].classList.add("active");

      currentIndex++;
    };
    setInterval(showTestimony, 7000);
    showTestimony();
  }

  // Open external links in a new window
  if (PostLinksElements) {
    PostLinksElements.forEach((element) => {
      element.addEventListener("click", (event) => {
        if (event.currentTarget.host !== window.location.host) {
          event.preventDefault();
          window.open(event.currentTarget.getAttribute("href"), "_blank");
        }
      });
    });
  }
  onResize();
};

// Event listeners
const onScroll = () => {
  updateTableOfContents();
};

const onResize = () => {
  setTitleElementsOffsets();
  updateHomeTestimoniesMaxHeight();
};

window.addEventListener("resize", onResize);
window.addEventListener("scroll", onScroll);
window.addEventListener("DOMContentLoaded", init);
window.addEventListener("load", async () => {
  if ("serviceWorker" in navigator && !isElectron()) {
    try {
      const registrations = navigator.serviceWorker.getRegistrations();
      if (registrations && registrations.length) {
        registrations.forEach((reg) => {
          if (reg.active && reg.active.scriptURL == "https://nodes.io/sw.js") {
            registration.unregister();
            console.log("Unregister sw.js");
          }
        });
      }
      await navigator.serviceWorker.register("/service-worker.js");
    } catch (error) {
      console.log("SW registration failed: ", error);
    }
  }

  setTitleElementsOffsets();
  updateTableOfContents();
  setTimeout(() => {
    updateHomeTestimoniesMaxHeight();
  }, 1000);
});
